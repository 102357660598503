<script>
import { defineComponent } from 'vue'
import { Line } from 'vue3-chart-v2'

export default defineComponent({
  name: 'MonthlyChart',
  props: ['data'],
  extends: Line,
  data() {
    return {
      labels: [],
      prices: [],
      orders: [],
    }
  },

  created() {
    this.data.forEach((value) => {
      var d = new Date(value[0])
      var n = d.getDate()
      this.prices.push(value[1])
      this.orders.push(value[2])
      this.labels.push(n)
    })
  },
  async mounted() {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          label: 'Price ',
          backgroundColor: '#f87979',
          borderColor: ['#E46651'],
          borderWidth: 2,
          data: this.prices,
        }
      ],
    }, {
      tooltips: {
        callbacks: {
          afterLabel: function (context) {
            const index = context.index;
            const order = this.orders[index];
            return `${order} orders`;
          }.bind(this),
        },
      }
    })
  }
})
</script>
