
import { Vue, Options } from 'vue-class-component'
import axios from '@/plugins/axios'
import LineChart from '../components/LineChart.vue'

interface Result {
  data: []
  orderCount: number
  totalSales: number
  listSalesPerDay: []
  productsCount: []
}

@Options({
  components: {
    LineChart
  }
})
export default class DashboardLayout extends Vue {
  name = 'Dashboard Page'
  totalOrder = 0
  dataCountProduct = []
  options = []
  totalSales = 0
  dataChart = []
  renderComponent = true
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  month = ''
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  async created() {
    this.getOverview()
    this.getMonth()
    this.month = this.options[0]
  }
  async mounted() {
    console.log('mounted');
    await this.$store.dispatch('setLoading', true, { root: true })
    await new Promise(r => setTimeout(r, 1000));
    await this.loadChart();
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getOverview() {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('user/statistics/overview', {
      headers: this.headers
    })
    if (res) {
      this.totalOrder = res.orderCount
      this.dataCountProduct = res.productsCount
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  getMonth() {
    let monthSalesItems: any = []
    let now = new Date()
    var _month = now.getMonth() + 1
    var _year = now.getFullYear()
    for (var i = 0; i < 24; i++) {
      _month == 0 && (_year--, (_month = 12))
      monthSalesItems.push(this.monthNames[_month - 1] + '|' + _year.toString())
      _month--
    }
    this.options = monthSalesItems
  }
  async loadChart() {
    console.log('loadChart')
    this.renderComponent = false
    let res: Result
    var tmp = this.month.split('|')
    var month = this.monthNames.findIndex((item) => item == tmp[0]) + 1
    var year = parseInt(tmp[1])
    res = await axios.get('user/statistics/sales', {
      headers: this.headers,
      params: { month: month, year: year }
    })
    this.totalSales = res.totalSales
    this.dataChart = res.listSalesPerDay
    this.$nextTick(() => {
      this.renderComponent = true
    })
  }
}
